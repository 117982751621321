import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Card, Form } from 'react-bootstrap'
import { RBA_ADMIN } from '../../utils/rba'
// import { Link } from 'react-router-dom'

import { getClientDetailById, updateClient } from '../../actions/clientActions'
import { Message, Loader } from '../../components/componentLib'

export const AdminMrm = ({ history, match }) => {
  const mrmId = '66e83387bd414ba9a17d709b'

  //LOCAL SCOPE
  const [updateSuccessLocal, setUpdateSuccessLocal] = useState(false)
  const [localMrmClients, setLocalMrmClients] = useState([])

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const clientDetail = useSelector((state) => state.clientDetail)
  const { loading, error, client, loadingUpdate, errorUpdate, successUpdate } = clientDetail

  useEffect(() => {
    if (client) {
      setLocalMrmClients(client.mrmClients)
    }
  }, [client])

  //CHECK LOGIN
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (RBA_ADMIN.includes(userInfo?.role)) {
      dispatch(getClientDetailById(mrmId))
    } else {
      history.push('/')
    }
  }, [history, dispatch, match, userInfo])

  useEffect(() => {
    successUpdate === true && setUpdateSuccessLocal(true)
    setTimeout(() => setUpdateSuccessLocal(false), 5000)
  }, [successUpdate])
  console.log('clientDetail', clientDetail)

  const handleFormUpdate = ({ mrmClientId, field, value }) => {
    console.log('handleFormUpdate', mrmClientId, field, value)
    let tempArray = JSON.parse(JSON.stringify(localMrmClients))
    let lineIndex = tempArray.findIndex((line) => line.mrmClientId === mrmClientId)
    let line = tempArray[lineIndex]
    line[field] = value
    tempArray[lineIndex] = line
    setLocalMrmClients(tempArray)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    console.log('update')
    const updatedClient = {
      _id: mrmId,
      mrmClients: localMrmClients,
    }
    console.log(updatedClient)
    dispatch(updateClient(updatedClient))
  }

  return (
    <Row>
      <Col md={12}>
        {(error || errorUpdate) && <Message variant="danger">{error || errorUpdate}</Message>}
        {updateSuccessLocal && <Message variant="success">Data Updated!</Message>}
        {(loading || loadingUpdate) && <Loader />}
        {localMrmClients && !error && (
          <Row>
            {localMrmClients.map((line) => (
              <Col key={line._id} xs={12} sm={6} lg={4} className="mb-4">
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title className="d-flex align-items-center mb-3">
                      {line.mrmLogoUrl ? (
                        <img
                          src={line.mrmLogoUrl}
                          alt={`${line.mrmCompanyName} logo`}
                          className="img-thumbnail me-2"
                          style={{ maxHeight: '80px', width: 'auto' }}
                        />
                      ) : (
                        <div
                          className="bg-light d-flex align-items-center justify-content-center me-2"
                          style={{ height: '80px', width: '80px' }}
                        >
                          <span className="text-muted small">No logo</span>
                        </div>
                      )}
                      <strong>{line.mrmCompanyName}</strong>
                    </Card.Title>
                    <Form.Group controlId={`mrmClientName-${line._id}`}>
                      <Form.Label>Internal Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter internal name"
                        value={line.mrqName}
                        onChange={(e) =>
                          handleFormUpdate({ mrmClientId: line.mrmClientId, field: 'mrqName', value: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId={`mrmClientNotes-${line._id}`}>
                      <Form.Label>Internal Notes:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Notes..."
                        value={line.mrqNotes}
                        onChange={(e) =>
                          handleFormUpdate({ mrmClientId: line.mrmClientId, field: 'mrqNotes', value: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId={`mrqDriveFolderId-${line._id}`}>
                      <Form.Label>Drive Folder ID (if blank, will use main MRM folder):</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="1_I6KVDarrLLIjtgjxEPLIA2MIWc6AB3I"
                        value={line.mrqDriveFolderId || ''}
                        onChange={(e) =>
                          handleFormUpdate({
                            mrmClientId: line.mrmClientId,
                            field: 'mrqDriveFolderId',
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId={`mrqInitials-${line._id}`}>
                      <Form.Label>Initials (if blank, will not apply any additional initials):</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter initials "
                        value={line.mrqInitials || ''}
                        onChange={(e) =>
                          handleFormUpdate({
                            mrmClientId: line.mrmClientId,
                            field: 'mrqInitials',
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={handleUpdate} className="mt-2">
                      Submit
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Col>
    </Row>
  )
}
