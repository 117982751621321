//This form is ONLY used for the "review" form where the CAD tech uploads the files when the status is "working"
//All other notes come from the modal component
//--DENG 28 Jan 2022

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Button, Form, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createNoteByJobId } from '../../actions/noteActions'
import { Loader } from '../componentLib'
import { RBA_CAD } from '../../utils/rba'

export const NoteCreateForm = ({ jobId, noteAction, num, pan1Width, pan2Width, userRole }) => {
  const dispatch = useDispatch()

  //Global State---
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  //LOCAL state---
  const [text, setText] = useState('')
  const [files, setFiles] = useState('')
  const [uploading, setUploading] = useState(false)

  //Note flagsLOCAL---
  const [flagCad, setFlagCad] = useState(false)
  const [flagReview, setFlagReview] = useState(false)
  const [flagReturn, setFlagReturn] = useState(false)
  const [flagRelease, setFlagRelease] = useState(false)
  const [flagRevision, setFlagRevision] = useState(false)
  const [flagRevisionResponse, setFlagRevisionResponse] = useState(false)
  const [flagRfi, setFlagRfi] = useState(false)
  const [flagRfiResponse, setFlagRfiResponse] = useState(false)
  const [requireText, setRequireText] = useState(true)
  const [headerText, setHeaderText] = useState('Leave a note here')
  const [fileFieldName, setFileFieldName] = useState('noteFile')

  //NOTE related logic---(set flags, set CAD flag, set requireText)
  useEffect(() => {
    if (noteAction === 'Review') {
      setFlagReview(true)
      setRequireText(false)
      setFlagCad(true)
      setFileFieldName('draftFile')
    }
    if (noteAction === 'Correction') {
      setFlagReturn(true)
      setFlagCad(true)
      setHeaderText('Kick back for corrections')
      setFileFieldName('draftFile')
    }
    if (noteAction === 'Release') {
      setFlagRelease(true)
      setRequireText(false)
    }
    if (noteAction === 'Revision') {
      setFlagRevision(true)
      setFileFieldName('revFile')
      setHeaderText('Request a Revision')
    }
    if (noteAction === 'RevisionResponse') {
      setFlagRevisionResponse(true)
      setHeaderText('Explain to the customer why this is not a revision')
    }
    if (noteAction === 'Rfi') {
      setFlagRfi(true)
      setHeaderText('Request more info from the customer--')
    }
    if (noteAction === 'RfiResponse') {
      setHeaderText('Respond to RFI')
      setFlagRfiResponse(true)
    }
  }, [noteAction])

  //Handlers, etc-----
  const submitHandler = (e) => {
    e.preventDefault()
    const note = {
      text,
      files,
      flagCad,
      flagReview,
      flagReturn,
      flagRelease,
      flagRevision,
      flagRevisionResponse,
      flagRfi,
      flagRfiResponse,
    }
    dispatch(createNoteByJobId(jobId, note))
  }

  const uploadFilesHandler = async (e) => {
    setUploading(true)
    //let tempFiles = []
    const formData = new FormData()
    for (let x = 0; x < e.target.files.length; x++) {
      //tempfiles.push(e.target.files[x])
      formData.append(fileFieldName, e.target.files[x], e.target.files[x].filename)
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post('/api/v1/upload', formData, config)

      setFiles([...files, ...data])
      //we're only checking the file(s) just added, not all files--
      if (noteAction === 'Review' && RBA_CAD.includes(userRole)) {
        checkJobNum(data, num, pan1Width, pan2Width, userRole)
      }
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const handleFlagCad = (e) => {
    e.preventDefault()
    setFlagCad(!flagCad)
  }

  return (
    <Card className="card-note">
      <Form onSubmit={submitHandler}>
        Note Action: {noteAction}
        <Form.Group controlId="text">
          <Form.Label>{headerText}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Note here..."
            value={text}
            required={requireText}
            onChange={(e) => setText(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="files">
          <Form.Label>Upload Files</Form.Label>
          <Form.File multiple id="file-input" label="Choose File(s)" custom onChange={uploadFilesHandler}></Form.File>
          {/* -OR link to a shared folder- */}
          <Form.Control
            hidden
            type="text"
            placeholder="Enter folder URL"
            value={files}
            onChange={(e) => setFiles(e.target.value)}
          ></Form.Control>
          {uploading && <Loader />}
          {files &&
            files.map((file, index) => (
              <div key={index}>
                {decodeURI(file)
                  .split(/(\\|\/)/g)
                  .pop()
                  .substring(20)}
              </div>
            ))}
          {RBA_CAD.indexOf(userInfo.role) >= 0 && (
            <div className="text-muted text-center">
              <span onClick={handleFlagCad} className={flagCad ? 'ml-2 mark' : 'ml-2 del'}>
                Internal CAD only note
              </span>
            </div>
          )}
        </Form.Group>
        <Button variant="primary" onClick={submitHandler} disabled={uploading}>
          Submit
        </Button>
        {uploading && (
          <Form.Text id="formUploadingHelp" muted>
            Hang on just a sec while these files upload...
          </Form.Text>
        )}
      </Form>
    </Card>
  )
}

const checkJobNum = (files, jobNum, panWidth, altPanWidth, userRole) => {
  let pdfs = 0
  let csvs = 0
  //let dwgs = 0
  console.log('files')
  console.log(files)
  // Original regex for the first format
  let re1 = /[0-9]{6}-[A-Za-z]{3}-[0-9]{3}-[A-Za-z]{3}-([0-9]+)/gm
  // New regex for the second format
  let re2 = /[0-9]{6}-[A-Za-z]{3}-[0-9]{3}-([0-9]+)/gm

  for (let i = 0; i < files.length; i++) {
    let fileName = decodeURI(files[i])
      .split(/(\\|\/)/g)
      .pop()
    if (fileName.includes(jobNum)) {
      console.log(jobNum)
      console.log(fileName)
    } else if (RBA_CAD.includes(userRole)) {
      alert('Filename does not include job number' + '\n\r filename is: ' + fileName + '\n job num is: ' + jobNum)
    }

    // Try both regex patterns
    let reMatch1 = fileName.match(re1)
    let reMatch2 = fileName.match(re2)

    console.log('reMatch1:', reMatch1, reMatch1?.[0])
    console.log('reMatch2:', reMatch2, reMatch2?.[0])

    // Use the first match that works
    let fileWidth = null
    if (reMatch1) {
      fileWidth = reMatch1[0].substring(19)
    } else if (reMatch2) {
      fileWidth = reMatch2[0].substring(15)
    }

    console.log('width:')
    console.log(fileWidth)
    // console.log('Pan1width:')
    // console.log(panWidth)
    // console.log('Pan2width:')
    // console.log(altPanWidth)
    if (fileWidth == panWidth || fileWidth == altPanWidth) {
    } else if (RBA_CAD.includes(userRole)) {
      alert(
        'Check PANEL WIDTH' +
          '\n\r filename is: ' +
          fileName +
          '\n Primary Panel Width should be: ' +
          panWidth +
          '\n Alt Panel Width should be: ' +
          altPanWidth
      )
    }

    let str = fileName
    switch (str.slice(str.length - 3)) {
      case 'csv':
        csvs = +1
        break
      case 'pdf':
        pdfs = +1
        break
      default:
        break
    }
  } //end for
  if (csvs > 0 && pdfs > 0) {
  } else if (RBA_CAD.includes(userRole)) {
    alert(`Possibly missing files--\n CSV's attached:  ${csvs} \n PDF's attached: ${pdfs}`)
  }
}
