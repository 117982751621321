import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Table } from 'react-bootstrap'
import axios from 'axios'

export const JobCopyData = ({ job }) => {
  const [tableClass, setTableClass] = useState(true)
  const [driveError, setDriveError] = useState(null)
  const [drivePath, setDrivePath] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dwgFiles, setDwgFiles] = useState([])
  const [isLoadingDwg, setIsLoadingDwg] = useState(false)
  const [dwgError, setDwgError] = useState(null)
  let table = document.querySelector('#dataTableCopy')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // Fetch Google Drive path
  useEffect(() => {
    if (job?.gdriveFolder) {
      async function fetchData() {
        setIsLoading(true)
        setDriveError(null)

        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          }

          // Get the full path
          const pathResponse = await axios.get(`/api/v1/admin/utils/get-gdrive-file-path/${job.gdriveFolder}`, config)
          setDrivePath(pathResponse.data.path)
        } catch (error) {
          console.error('Google Drive API error:', error)

          if (error.response) {
            if (error.response.status === 401) {
              setDriveError('Authentication failed. Please check your permissions.')
            } else if (error.response.status === 403) {
              setDriveError('Permission denied. You might not have access to this folder.')
            } else if (error.response.status === 404) {
              setDriveError('Folder not found. The Google Drive folder ID might be incorrect.')
            } else {
              setDriveError(error.response.data?.error?.message || 'Failed to fetch Google Drive data')
            }
          } else if (error.request) {
            setDriveError('No response received from server. Check your network connection.')
          } else {
            setDriveError(`Error: ${error.message}`)
          }
        } finally {
          setIsLoading(false)
        }
      }
      fetchData()
    }
  }, [job?.gdriveFolder, userInfo.token])

  // Fetch .dwg files automatically
  useEffect(() => {
    if (job?.gdriveFolder) {
      async function fetchDwgFiles() {
        setIsLoadingDwg(true)
        setDwgError(null)

        try {
          const config = {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          }

          const response = await axios.get(`/api/v1/admin/utils/search-dwg-files/${job.gdriveFolder}`, config)
          setDwgFiles(response.data.files)
          // console.log('dwg files', response.data.files)
        } catch (error) {
          console.error('Error fetching .dwg files:', error)
          setDwgError('Failed to fetch .dwg files. Please try again.')
        } finally {
          setIsLoadingDwg(false)
        }
      }
      fetchDwgFiles()
    }
  }, [job?.gdriveFolder, userInfo.token])

  const selectNode = (node) => {
    let range = document.createRange()
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
    select.addRange(range)
  }
  const deselectNode = (node) => {
    let range = document.createRange()
    range.selectNodeContents(node)
    let select = window.getSelection()
    select.removeAllRanges()
  }

  const copyHandler = () => {
    setTableClass(!tableClass)
    selectNode(table)
    document.execCommand('copy')
    deselectNode(table)
  }

  return (
    <>
      <div className="py-2">
        {/* File Explorer and DWG Files Section */}
        <div className="mt-3 p-3 border rounded bg-light">
          <div className="row">
            {/* Left Column - DWG Files Section */}
            <div className="col-md-6">
              {isLoadingDwg && (
                <div className="small text-muted mb-2">
                  <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                  Searching for .dwg files...
                </div>
              )}

              {dwgError && (
                <div className="text-danger small mb-2">
                  <strong>Error:</strong> {dwgError}
                </div>
              )}

              {dwgFiles.length > 0 && (
                <div className="mb-3">
                  <h6 className="text-muted">Found {dwgFiles.length} .dwg files:</h6>
                  <ul className="list-unstyled small">
                    {dwgFiles.map((file, index) => (
                      <li key={index} className="mb-1">
                        <a
                          href={`localexplorer:${userInfo.driveLetter}:${drivePath}/${file.path}`}
                          target="_self"
                          className="text-primary"
                        >
                          <i className="fas fa-file-alt mr-1"></i> {file.path}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Right Column - Open Folder Button */}
            <div className="col-md-6">
              {isLoading && <div className="mt-1">Loading Google Drive data...</div>}
              {driveError && (
                <div className="mt-1 text-danger">
                  <strong>Error:</strong> {driveError}
                </div>
              )}

              {/* Button to open folder locally */}
              {drivePath && userInfo.driveLetter && (
                <Button
                  variant="primary"
                  size="md"
                  as="a"
                  href={`localexplorer:${userInfo.driveLetter}:${drivePath}`}
                  target="_self"
                  className="mr-2"
                >
                  <i className="fas fa-folder-open mr-1"></i> Open Folder
                </Button>
              )}

              <div className="text-muted small mt-4">
                <a href={`https://drive.google.com/drive/folders/${job.gdriveFolder}`} target="_blank" rel="noreferrer">
                  Open at drive.google.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Button variant="info" onClick={copyHandler}>
        <i className="fas fa-copy"></i> Copy/Toggle Data Row
      </Button>
      <div id="dataTableCopyDiv" className={tableClass ? 'display-offscreen' : 'table-responsive-md'}>
        <Table id="dataTableCopy">
          <tbody>
            <tr>
              <td className="p-1 mw-10">{job.owner?.inits}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1 mw-10">{job.num}</td>
              <td className="p-1 mw-10">{job.owner?.inits}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1 mw-10">{job.owner?.companyName}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.name}</td>
              <td className="p-1">
                {job.street} {job.city} {job.state}
              </td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.pan1Name}</td>
              <td className="p-1">{job.pan1Width}</td>
              <td className="p-1">{job.pan2Name}</td>
              <td className="p-1">{job.pan2Width}</td>
              <td className="p-1">{job.specialReq}</td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1"></td>
              <td className="p-1">{job.owner?.inits}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
