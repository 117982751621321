import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAIL_REQUEST,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_FAIL,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_GET_ALL_REQUEST,
  USER_GET_ALL_SUCCESS,
  USER_GET_ALL_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_UPDATE_BY_ID_REQUEST,
  USER_UPDATE_BY_ID_SUCCESS,
  USER_UPDATE_BY_ID_FAIL,
} from '../actions/constants'

export const userLoginReducer = (state = { userInfo: [] }, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, ...state }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return { state: undefined }
    case USER_PROFILE_UPDATE_REQUEST:
      return { ...state, loading: true }
    case USER_PROFILE_UPDATE_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDetailReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAIL_REQUEST:
      return { ...state, loading: true }
    case USER_DETAIL_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userProfileUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return { ...state, loading: true }
    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      }
    case USER_PROFILE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case 'USER_PROFILE_UPDATE_RESET':
      return { ...state, success: false }
    case USER_UPDATE_BY_ID_REQUEST:
      return { ...state, loading: true }
    case USER_UPDATE_BY_ID_SUCCESS:
      return {
        loading: false,
        success: true,
        user: action.payload,
      }
    case USER_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userIndexReducer = (state = { users: {} }, action) => {
  switch (action.type) {
    case USER_GET_ALL_REQUEST:
      return { ...state, loading: true }
    case USER_GET_ALL_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_GET_ALL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
